.fav-swiper {
  width: 100%;
  height: 100%;
}

.fav-swiper .swiper-wrapper {
  align-items: center;
}

.fav-swiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 650px;
}

.fav-swiper .swiper-pagination-bullet {
  width: 0.9em !important;
  height: 0.9em !important;
  border-radius: 50%;
  border: 1px solid #F16449;
  opacity: 1;
  margin: 0 0.7em 1em !important;
  background: none;
}

.fav-swiper .swiper-pagination-bullet-active {
  background-color: #F16449;
}

.fav-swiper .swiper-button-next,
.fav-swiper .swiper-button-prev {
  color: rgb(248, 113, 68);
}

.react-multi-carousel-item {
  padding: 5px;
}