.share-form {
  position: relative;
  background-color: #B1C5AC;
  border: 1px solid #000000;
  /* width: 45vw; */
}

.share-input {
  background: none;
  border: none;
  border-bottom: 1px solid #030201;
  outline: none;
  appearance: none;
  border-radius: 0;
  color: #111111;
  font-weight: bold;
  letter-spacing: 0.1em;
  min-width: 100%;
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: auto;
}

.share-input::placeholder {
  color: #030201;
  text-transform: uppercase;
}

.share-control {
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: 3em;
  height: 3em;
  padding: 0.2em;
}

.share-control:hover {
  opacity: 0.6;
}