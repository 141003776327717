.map-container {
    height: calc(100% - 35px);
    width: 45%;
    position: absolute;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

.mapboxgl-canvas {
    height: 600px;
}

    .marker {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f7bc42;
        border: 1px solid;
      }

.react-player video {
    object-fit: cover;
}