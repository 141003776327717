.cinema1 {
    text-align: left;
    width: 55%;
    bottom: 3em;
    left: 0.5em;
}

.cinema2 {
    text-align: right;
    width: 45%;
    bottom: 3.5em;
    right: 0.5em;
}

.cinema3 {
    text-align: left;
    width: 45%;
    left: 0.5em;
    top: 1.5em;
}